////
///
/// Table placeholders
///
/// @group table
/// @author Clint Kolodziej
///
////

@import "../modules/grid";
@import "../defaults/table";

///
/// Table with padding
///

%table-padded {
	th,
	td {
		padding-top: $line-height * $table-padded-padding-vertical;
		padding-bottom: $line-height * $table-padded-padding-vertical;
		padding-left: $line-height * $table-padded-padding-horizontal;
		padding-right: $line-height * $table-padded-padding-horizontal;
	}
}

///
/// Table with borders
///

%table-bordered {
	margin-top: $line-height - em(1) * 1em;			//subtract 1px border
	margin-bottom: $line-height - em(1) * 1em;		//subtract 1px border
	border: 1px solid $border-color;
	border-left: 0;

	th,
	td {
		border-left: 1px solid $border-color;
	}
}

///
/// Table with even/odd striping
///

%table-striped {
	tbody > tr:nth-child(odd) > td,
	tbody > tr:nth-child(odd) > th {
		background-color: $table-striped-background-color;
	}
}
