﻿//
// Modules
//

@import "watercolor/elements/table";

//
// Table
//

table {
	@include table(padded, bordered, striped);
}