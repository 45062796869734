﻿//
// Button
//

.button {
	display: inline-block;
	padding: .75em 1.25em;
	background: $secondary-color;
	color: #fff !important;
	text-decoration: none !important;
    border: none;
    border-radius: .25em;
    outline: none;
    box-shadow: none;
    font-size: 1.125em;
    font-family: $button-font-family;
    font-weight: $button-font-weight;
    text-shadow: none;
	transition: all, .1s;
    user-select: none;
    cursor: pointer;

	&:hover {
        background: $primary-color;
        border: none;
	}

    &[disabled]:hover {
        background: $secondary-color;
    }

    &.alternate {
        background: $primary-color;

        &:hover {
            background: $secondary-color;
        }
    }

    &.ghost {
        padding: .5em 1em;
        border-width: .125em;
        border-style: solid;
        border-color: rgba(255,255,255,0.6);
        font-weight: 700;
        background: transparent;
        text-shadow: 0 .1em .1em rgba(0,0,0,0.1);

        &:hover {
            background: #fff;
            color: #555 !important;
            text-shadow: none;
        }
    }
}