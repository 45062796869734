﻿//
// Dialog
//
// Reference: https://tympanus.net/Development/DialogEffects/index.html
//

.dialog,
.dialog__overlay {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.dialog {
	position: fixed;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	pointer-events: none;
	z-index: 999;
}

.dialog__overlay {
	position: absolute;
	z-index: 1;
	background: rgba(55, 58, 71, 0.9);
	opacity: 0;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
	-webkit-backface-visibility: hidden;
}

.dialog--open .dialog__overlay {
	opacity: 1;
	pointer-events: auto;
}

.dialog__content {
	width: 50%;
	max-width: 560px;
	min-width: 290px;
	background: #fff;
	padding: 3em;
	text-align: center;
	position: relative;
	z-index: 5;
	opacity: 0;
}

.dialog__close {
	position: absolute;
	top: .25em;
	right: .25em;
	width: 1.25em;
	height: 1.25em;
	color: #888;
	font-weight: 400;
	font-size: 1.75em;
	line-height: 1.25em;
	vertical-align: middle;

	&:hover {
		color: #444;
	}

	&::before {
		content: '\00d7';
	}
}

.dialog--open .dialog__content {
	pointer-events: auto;
}

//
// Sandra Effect
//

.dialog.dialog--open .dialog__content,
.dialog.dialog--close .dialog__content {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.dialog.dialog--open .dialog__content {
	-webkit-animation-name: anim-open;
	animation-name: anim-open;
}

.dialog.dialog--close .dialog__content {
	-webkit-animation-name: anim-close;
	animation-name: anim-close;
}

@-webkit-keyframes anim-open {
	0% { opacity: 0; -webkit-transform: scale3d(1.1, 1.1, 1); }
	100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); }
}

@keyframes anim-open {
	0% { opacity: 0; -webkit-transform: scale3d(1.1, 1.1, 1); transform: scale3d(1.1, 1.1, 1); }
	100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
}

@-webkit-keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: scale3d(0.9, 0.9, 1); }
}

@keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: scale3d(0.9, 0.9, 1); transform: scale3d(0.9, 0.9, 1); }
}