//
// Typography
//

$body-font-size: 1 !default;
$body-font-family: 'Roboto', sans-serif;
$body-font-weight: 300 !default;

//$header-font-family: 'Roboto', sans-serif;
//$header-font-weight: 700 !default;

$header-font-family: 'Cinzel', sans-serif;
$header-font-weight: 300 !default;

$button-font-family: 'Cinzel', sans-serif;
$button-font-weight: 300 !default;
