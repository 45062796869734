﻿//
// Alert
//

.alert {
	display: none;
	padding: 8px 14px 8px 14px;
	margin-bottom: 20px;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
	background-color: #fcf8e3;
	border: 1px solid #fbeed5;
	border-radius: 4px;

	&.show {
		display: block;
	}

	&.success {
	  color: #468847;
	  background-color: #dff0d8;
	  border-color: #d6e9c6;
	}

	&.error {
	  color: #b94a48;
	  background-color: #f2dede;
	  border-color: #eed3d7;
	}
}